<template>
  <div class="home">
    <div class="login" ref="login">
      <h2>{{ msg }}</h2>
      <!--钉钉扫码登录 -->
      <div class="codeLogin_div">
        <a-tabs v-model="active" @change="callback">
          <a-tab-pane key="1" tab="企业微信登录" force-render>
            <div class="codeLogin wxCode" id="code"></div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="钉钉登录" force-render>
            <div
              class="codeLogin"
              id="login_container"
              v-show="bol"
              ref="login_container"
            ></div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="records">
      <a href="https://beian.miit.gov.cn" target="_blank"
        >浙ICP备16004982号-1</a
      >
    </div>
  </div>
</template>

<script>
// 引入图标字体
import "../assets/icon-font/iconfont.css";
//引入钉钉登录文件
import "../lib/dingdingLogin";

export default {
  name: "Login",
  data() {
    return {
      msg: "统一登录",
      active: "1",
      title: null,
      user: {
        name: "",
        password: "",
      },
      type: "password",
      href: null,
      bol: true,
      loginType: null,
      appId: null,
      redirectUri: null,
    };
  },
  watch: {
    password(val) {
      this.$refs.password.$el.onkeydown = (e) => {
        if (
          e.keyCode == 13 &&
          this.user.name != "" &&
          this.user.password != ""
        ) {
          this.login();
        }
      };
    },
  },
  computed: {
    password() {
      return this.user.password;
    },
  },
  mounted() {
    this.WwLogin();
    if (typeof window.addEventListener != "undefined") {
      window.addEventListener("message", this.hanndleMessage, false);
    } else if (typeof window.attachEvent != "undefined") {
      window.attachEvent("onmessage", this.hanndleMessage);
    }
  },
  methods: {
    // 切换tab
    callback(key) {
      if (key === "1") {
        this.WwLogin();
      }
      if (key === "2") {
        this.loginSystem();
      }
    },
    // 创建微信二维码
    WwLogin() {
      let data = this.handleUrl();
      this.$http.post("/sys/getState", data).then((res) => {
        if (res.result == 200) {
          let state = res.data.state;
          this.$http.post("/sys/wechatSetting").then((res) => {
            if (res.result === 200) {
              let wxData = res.data.wechatLogin;
              wxData.state = state;
              window.WwLogin({
                id: "code", //显示二维码的容器id
                appid: wxData.appid,
                agentid: wxData.agentid, //企业微信的cropID，在 企业微信管理端->我的企业 中查看
                redirect_uri: wxData.redirectUri, //重定向地址，需要进行UrlEncode
                state: wxData.state, //用于保持请求和回调的状态，授权请求后原样带回给企业。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议企业带上该参数
              });
            }
          });
        }
      });
    },
    handleUrl() {
      //处理url
      let url = window.location.href,
        str = null,
        arrUrl = null,
        data = {},
        key = null,
        value = null;
      str = url.split("?")[1];
      if (str) {
        arrUrl = str.split("&");
        arrUrl.map((item) => {
          key = item.split("=")[0];
          value = item.split("=")[1];
          data[key] = value;
        });
      }
      return data;
    },
    hanndleMessage(event) {
      //钉钉扫码登录
      let loginTmpCode = null,
        href = null,
        url = null,
        arrUrl = null,
        data = null,
        state = null,
        origin = event.origin; //扫码来源
      if (origin == "https://login.dingtalk.com") {
        //ddLogin扫码
        loginTmpCode = event.data;
        data = this.handleUrl();
        this.$http.post("/sys/getState", data).then((res) => {
          if (res.result == 200) {
            state = res.data.state;
            href =
              "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
              this.appId +
              "&response_type=code&scope=snsapi_login&state=" +
              state +
              "&redirect_uri=" +
              encodeURI(this.redirectUri) +
              "&loginTmpCode=" +
              loginTmpCode;
            window.location.href = href;
          }
        });
      }
    },
    codeLogin() {
      //二维码登录
      let url = encodeURIComponent(this.redirectUri),
        goto_url = encodeURIComponent(
          "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
            this.appId +
            "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
            url
        ),
        obj = DDLogin({
          id: "login_container",
          goto: goto_url,
          style:
            "border:none;background-color:transparent ;color:#FFFFFF !important;",
          width: "280",
          height: "290",
        });
    },
    loginSystem() {
      //登录认证
      this.$http.post("/sys/setting").then((res) => {
        if (res.result == 200) {
          if (res.data.dingTalkLogin) {
            this.appId = res.data.dingTalkLogin.appId;
            this.redirectUri = res.data.dingTalkLogin.redirectUri;
          }
          if (res.data.loginType === "DINGTALK_LOGIN") {
            //钉钉二维码登录
            this.bol = true;
            this.codeLogin();
            this.$refs.login.classList.remove("login-cover");
          } else {
            this.bol = false;
            this.$refs.login.classList.add("login-cover");
          }
          this.msg = res.data.header;
          document.title = res.data.title;
        }
      });
    },
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../public/img/reduce-bgc.png") no-repeat;
  background-size: cover;
  background-position: center 0;
  /* z-index: -1; */
}

.login {
  width: 500px;
  height: 550px;
  box-sizing: border-box;
  margin: 150px auto 0;
  position: relative;
  border: 1px solid #ffffff2e;
  border-radius: 2%;
  background: #ffffff2e;
  padding-top: 25px;
  opacity: 0.9;
}
.records {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}
.login-cover {
  height: 350px;
}
.login h2 {
  text-align: center;
  color: white;
}
.account {
  margin-top: 50px;
}
.login .el-input {
  display: block;
  width: 360px;
  margin: 0px auto;
}
.login .el-input:nth-of-type(2) {
  margin: 25px auto;
}
.mark {
  font-size: 20px;
  position: absolute;
  top: 182px;
  right: 78px;
  color: #708998;
}

.login .operate {
  text-align: center;
}
.login .el-button {
  width: 360px;
}
/*二维码登录 */
/* .codeLogin_div {
		 display: flex;
		 justify-content: space-around;
	 } */
.codeLogin {
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}
.border {
  width: 210px;
  height: 210px;
  position: relative;
  top: -256px;
  left: 143px;
  border: 1px solid #ffffff;
}
.text {
  width: 170px;
  height: 21px;
  position: absolute;
  top: 347px;
  left: 142px;
  background: #766661;
  color: #ffffff;
  font-size: 14px;
}
</style>
